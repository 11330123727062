const API_ENDPOINT = "https://pokeapi.co/api/v2/";
import Me from "../assets/images/me.png";
import Gamebot from "../assets/images/gamebot.png";
import Therapist from "../assets/images/therapist.png";
import Friend from "../assets/images/friend.png";
import kurian from "../assets/images/kurian.png";
import hari from "../assets/images/hari.png";
import shruthi from "../assets/images/shruthi.png";

const AI_AGENT_LIST = [
  // TODO: Need to update serverURL and token
  {
    id: "1",
    persona: "Kurian",
    relation: "Myself",
    serverUrl: "wss://demo-app-llkt0pq2.livekit.cloud",
    imageSrc: kurian,
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZGVudGl0eSI6IiIsIm5hbWUiOiJteSBuYW1lIiwidmlkZW8iOnsicm9vbUNyZWF0ZSI6ZmFsc2UsInJvb21MaXN0IjpmYWxzZSwicm9vbVJlY29yZCI6ZmFsc2UsInJvb21BZG1pbiI6ZmFsc2UsInJvb21Kb2luIjp0cnVlLCJyb29tIjoiYXNqLXJvb20iLCJjYW5QdWJsaXNoIjp0cnVlLCJjYW5TdWJzY3JpYmUiOnRydWUsImNhblB1Ymxpc2hEYXRhIjp0cnVlLCJjYW5QdWJsaXNoU291cmNlcyI6W10sImNhblVwZGF0ZU93bk1ldGFkYXRhIjpmYWxzZSwiaW5ncmVzc0FkbWluIjpmYWxzZSwiaGlkZGVuIjpmYWxzZSwicmVjb3JkZXIiOmZhbHNlLCJhZ2VudCI6ZmFsc2V9LCJzaXAiOnsiYWRtaW4iOmZhbHNlLCJjYWxsIjpmYWxzZX0sImF0dHJpYnV0ZXMiOnt9LCJtZXRhZGF0YSI6IiIsInNoYTI1NiI6IiIsImtpbmQiOiIiLCJzdWIiOiJpZGVudGl0eSIsImlzcyI6IkFQSWl0bUM1OHpSRUN6OSIsIm5iZiI6MTcyODQ3ODgxMywiZXhwIjoxNzMxMDcwODEzfQ.07muaHmEV_yH9b-LVQakFLbVWCigyyuNbdFw7fSYioE",
  },
  {
    id: "2",
    persona: "Hari",
    relation: "Best friend",
    serverUrl: "wss://projectxbestfriend-jqitcff8.livekit.cloud",
    imageSrc: hari,
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZGVudGl0eSI6IiIsIm5hbWUiOiJteSBuYW1lIiwidmlkZW8iOnsicm9vbUNyZWF0ZSI6ZmFsc2UsInJvb21MaXN0IjpmYWxzZSwicm9vbVJlY29yZCI6ZmFsc2UsInJvb21BZG1pbiI6ZmFsc2UsInJvb21Kb2luIjp0cnVlLCJyb29tIjoiYXNqLXJvb20iLCJjYW5QdWJsaXNoIjp0cnVlLCJjYW5TdWJzY3JpYmUiOnRydWUsImNhblB1Ymxpc2hEYXRhIjp0cnVlLCJjYW5QdWJsaXNoU291cmNlcyI6W10sImNhblVwZGF0ZU93bk1ldGFkYXRhIjpmYWxzZSwiaW5ncmVzc0FkbWluIjpmYWxzZSwiaGlkZGVuIjpmYWxzZSwicmVjb3JkZXIiOmZhbHNlLCJhZ2VudCI6ZmFsc2V9LCJzaXAiOnsiYWRtaW4iOmZhbHNlLCJjYWxsIjpmYWxzZX0sImF0dHJpYnV0ZXMiOnt9LCJtZXRhZGF0YSI6IiIsInNoYTI1NiI6IiIsImtpbmQiOiIiLCJzdWIiOiJpZGVudGl0eSIsImlzcyI6IkFQSXBTVGE0WXBKa0ZkVSIsIm5iZiI6MTcyODQ3ODgxNSwiZXhwIjoxNzMxMDcwODE1fQ.8TzqbGtEn5sC8zjVWuN2vSfkq6KA00dU5oyttU6tZh4",
  },
  {
    id: "3",
    persona: "Shruti",
    relation: "Therapist",
    serverUrl: "wss://therapist-agent-ecg49j3l.livekit.cloud",
    imageSrc: shruthi,
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZGVudGl0eSI6IiIsIm5hbWUiOiJteSBuYW1lIiwidmlkZW8iOnsicm9vbUNyZWF0ZSI6ZmFsc2UsInJvb21MaXN0IjpmYWxzZSwicm9vbVJlY29yZCI6ZmFsc2UsInJvb21BZG1pbiI6ZmFsc2UsInJvb21Kb2luIjp0cnVlLCJyb29tIjoiYXNqLXJvb20iLCJjYW5QdWJsaXNoIjp0cnVlLCJjYW5TdWJzY3JpYmUiOnRydWUsImNhblB1Ymxpc2hEYXRhIjp0cnVlLCJjYW5QdWJsaXNoU291cmNlcyI6W10sImNhblVwZGF0ZU93bk1ldGFkYXRhIjpmYWxzZSwiaW5ncmVzc0FkbWluIjpmYWxzZSwiaGlkZGVuIjpmYWxzZSwicmVjb3JkZXIiOmZhbHNlLCJhZ2VudCI6ZmFsc2V9LCJzaXAiOnsiYWRtaW4iOmZhbHNlLCJjYWxsIjpmYWxzZX0sImF0dHJpYnV0ZXMiOnt9LCJtZXRhZGF0YSI6IiIsInNoYTI1NiI6IiIsImtpbmQiOiIiLCJzdWIiOiJpZGVudGl0eSIsImlzcyI6IkFQSWozY3BiblVRdFlZZSIsIm5iZiI6MTcyODQ3ODgxNSwiZXhwIjoxNzMxMDcwODE1fQ.55qIjTWu6qYqTEsYRp0kQ4Ns42Qgtj5oKOLkl-UxCmo",
  },
  {
    id: "5",
    persona: "Game",
    relation: "bot",
    serverUrl: "wss://projectx-5jd4vicb.livekit.cloud",
    imageSrc: Gamebot,
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZGVudGl0eSI6IiIsIm5hbWUiOiJteSBuYW1lIiwidmlkZW8iOnsicm9vbUNyZWF0ZSI6ZmFsc2UsInJvb21MaXN0IjpmYWxzZSwicm9vbVJlY29yZCI6ZmFsc2UsInJvb21BZG1pbiI6ZmFsc2UsInJvb21Kb2luIjp0cnVlLCJyb29tIjoiYXNqLXJvb20iLCJjYW5QdWJsaXNoIjp0cnVlLCJjYW5TdWJzY3JpYmUiOnRydWUsImNhblB1Ymxpc2hEYXRhIjp0cnVlLCJjYW5QdWJsaXNoU291cmNlcyI6W10sImNhblVwZGF0ZU93bk1ldGFkYXRhIjpmYWxzZSwiaW5ncmVzc0FkbWluIjpmYWxzZSwiaGlkZGVuIjpmYWxzZSwicmVjb3JkZXIiOmZhbHNlLCJhZ2VudCI6ZmFsc2V9LCJzaXAiOnsiYWRtaW4iOmZhbHNlLCJjYWxsIjpmYWxzZX0sImF0dHJpYnV0ZXMiOnt9LCJtZXRhZGF0YSI6IiIsInNoYTI1NiI6IiIsImtpbmQiOiIiLCJzdWIiOiJpZGVudGl0eSIsImlzcyI6IkFQSVQ0RnRRdUF4ZG9LZyIsIm5iZiI6MTcyODQ3ODgxMCwiZXhwIjoxNzMxMDcwODEwfQ.p-CjXSna9_irISzh6RJLY__ZAeqGLU9ZBOJ4ovnOdGQ",
  },
  {
    id: "6",
    persona: "Assistant",
    relation: "bot",
    serverUrl: "wss://projectx-2ef20wul.livekit.cloud",
    imageSrc: Gamebot,
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZGVudGl0eSI6IiIsIm5hbWUiOiJteSBuYW1lIiwidmlkZW8iOnsicm9vbUNyZWF0ZSI6ZmFsc2UsInJvb21MaXN0IjpmYWxzZSwicm9vbVJlY29yZCI6ZmFsc2UsInJvb21BZG1pbiI6ZmFsc2UsInJvb21Kb2luIjp0cnVlLCJyb29tIjoiYXNqLXJvb20iLCJjYW5QdWJsaXNoIjp0cnVlLCJjYW5TdWJzY3JpYmUiOnRydWUsImNhblB1Ymxpc2hEYXRhIjp0cnVlLCJjYW5QdWJsaXNoU291cmNlcyI6W10sImNhblVwZGF0ZU93bk1ldGFkYXRhIjpmYWxzZSwiaW5ncmVzc0FkbWluIjpmYWxzZSwiaGlkZGVuIjpmYWxzZSwicmVjb3JkZXIiOmZhbHNlLCJhZ2VudCI6ZmFsc2V9LCJzaXAiOnsiYWRtaW4iOmZhbHNlLCJjYWxsIjpmYWxzZX0sImF0dHJpYnV0ZXMiOnt9LCJtZXRhZGF0YSI6IiIsInNoYTI1NiI6IiIsImtpbmQiOiIiLCJzdWIiOiJpZGVudGl0eSIsImlzcyI6IkFQSTRxQ0JYdDhHY1d0RCIsIm5iZiI6MTcyODQ3ODgxMywiZXhwIjoxNzMxMDcwODEzfQ.C5nGF2LmUK7ikQm7SPvahSoP011rfsAD1AFXbHv4KeY",
  },
];

export { AI_AGENT_LIST, API_ENDPOINT };
