export const tabs = [
  { title: "All", value: "All" },
  { title: "Images", value: "image" },
  { title: "Videos", value: "video" },
];

export const galleryData = [
  {
    id: 1,
    type: "image",
    url: "https://picsum.photos/200/300",
    title: "Image 1",
  },
  {
    id: 2,
    type: "video",
    url: "https://www.w3schools.com/tags/movie.mp4",
    title: "Video 1",
  },
  {
    id: 3,
    type: "image",
    url: "https://picsum.photos/200/",
    title: "File 1",
  },
  {
    id: 4,
    type: "image",
    url: "https://picsum.photos/200/301",
    title: "Image 2",
  },
  {
    id: 5,
    type: "video",
    url: "https://www.w3schools.com/tags/movie.mp4",
    title: "Video 2",
  },
  {
    id: 6,
    type: "image",
    url: "https://picsum.photos/200/",
    title: "File 2",
  },
  {
    id: 7,
    type: "image",
    url: "https://picsum.photos/200/302",
    title: "Image 3",
  },
  {
    id: 8,
    type: "video",
    url: "https://www.w3schools.com/tags/movie.mp4",
    title: "Video 3",
  },
  {
    id: 9,
    type: "image",
    url: "https://picsum.photos/200/",
    title: "File 3",
  },
  {
    id: 10,
    type: "image",
    url: "https://picsum.photos/200/303",
    title: "Image 4",
  },
  {
    id: 11,
    type: "video",
    url: "https://www.w3schools.com/tags/movie.mp4",
    title: "Video 4",
  },
  {
    id: 12,
    type: "image",
    url: "https://picsum.photos/200/",
    title: "File 4",
  },
];

export const dummyData = [
  {
    id: 1,
    sender: "other",
    type: "text",
    content: "Hello!",
    time: "10:15 AM",
  },
  {
    id: 2,
    sender: "me",
    type: "text",
    content: "Hi, how are you?",
    time: "10:16 AM",
  },
  {
    id: 3,
    sender: "other",
    type: "image",
    content:
      "https://preview.redd.it/male-random-selfie-27m-v0-s6bd3ohvwx4c1.jpg?width=1080&crop=smart&auto=webp&s=a3023b01de21f3d5986c63d0ae8a61e09c8e4c9e", // Dummy image URL
    time: "10:18 AM",
  },
  {
    id: 4,
    sender: "me",
    type: "audio",
    content: "voice-message.mp3", // Dummy audio file
    time: "10:20 AM",
  },
  {
    id: 7,
    sender: "me",
    type: "text",
    content: "Hi, how are you?",
    time: "10:16 AM",
  },
];
