const routesPath = {
  ALL: '*',
  DEFAULT: '/',
  SIGNUP: '/signup',
  LOGIN: '/login',
  HOME: '/home',
  VOICE: '/voice',
  TEST: '/test',
  ABOUT : '/about',
  CHAT : '/chat/:id',
  GAME: '/game',
  PROFILE : '/profile/:id',
  TODO : '/todo',
  ADD_PERSON: "/add-person",
  REPORTS: "/reports",
  CAMERA_ASSIST: "/camera-assist",
};

export default routesPath;
